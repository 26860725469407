import { App } from "./utils";
import {
  addImageHandlers,
  addNavigationHandlers,
  handleInvalid,
  handleSubmit,
  persistFormData,
  restoreFormData,
} from "./modules";

window.App = new App({
  ...(window.App || {}),
  eventListeners: {
    DOMContentLoaded: [
      addImageHandlers,
      addNavigationHandlers,
      restoreFormData,
    ],
    invalid: handleInvalid,
    submit: handleSubmit,
    visibilitychange: persistFormData,
  },
});

window.App.init();
