class StorageModule {
  constructor(storage = localStorage) {
    this._storage = storage;
    this._formatKey = function formatKey(str) {
      this.prefix ??= "bsa_";
      return `${this.prefix}${str}`;
    };
  }

  get length() {
    try {
      return this._storage.length;
    } catch (error) {
      return 0;
    }
  }

  get(key) {
    try {
      const data = this._storage.getItem(this._formatKey(key));
      return JSON.parse(data);
    } catch (error) {
      return undefined;
    }
  }

  set(key, data) {
    try {
      this._storage.setItem(this._formatKey(key), JSON.stringify(data));
    } catch (error) {
      // ignore
    }
  }

  remove(key) {
    try {
      this._storage.removeItem(this._formatKey(key));
    } catch (error) {
      // ignore
    }
  }

  clear() {
    try {
      this._storage.clear();
    } catch (error) {
      // ignore
    }
  }
}

const storage = Object.assign(new StorageModule(), {
  session: new StorageModule(sessionStorage),
});

export default storage;
