import imageUrlBuilder from "@sanity/image-url";

export function addImageHandlers() {
  const observer = getImageObserver();
  const images = document.querySelectorAll("img[data-asset]");
  if (observer) {
    images.forEach((el) => {
      observer.observe(el);
    });
  }
}

function getImageObserver() {
  if (!("IntersectionObserver" in window) || !("ResizeObserver" in window)) {
    return undefined;
  }

  const resizeObserver = new ResizeObserver((entries) => {
    entries.forEach((entry) => {
      const { asset, width: previous = 0 } = entry.target.dataset;
      let width = 0;
      if ("borderBoxSize" in entry) {
        width = entry.borderBoxSize[0]?.inlineSize;
      }
      if (!width && "contentBoxSize" in entry) {
        width = entry.contentBoxSize.inlineSize;
      }
      width = getImageWidth(width);
      if (width > previous) {
        const url = getImageSrc(asset).width(width).url();
        // eslint-disable-next-line no-param-reassign
        entry.target.src = url;
        // eslint-disable-next-line no-param-reassign
        entry.target.dataset.width = width;
      }
    });
  });

  const intersectionObserver = new IntersectionObserver(
    (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          observer.unobserve(entry.target);
          resizeObserver.observe(entry.target);
        }
      });
    },
    { rootMargin: "384px 0px" },
  );

  return intersectionObserver;
}

function getImageWidth(width) {
  return Math.ceil((width * window.devicePixelRatio) / 32) * 32;
}

let imageBuilder;

function getImageSrc(asset) {
  if (!imageBuilder) {
    imageBuilder = imageUrlBuilder(window.App?.env?.sanity || {});
  }
  return imageBuilder.image(asset).format("webp");
}
