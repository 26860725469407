export default class App {
  initialized = false;

  constructor(options = {}) {
    this.events = new Set();
    this.listeners = new Map();
    this.env = options.env || {};

    if (options.eventListeners) {
      Object.entries(options.eventListeners).forEach(([type, ...listeners]) => {
        listeners.flat().forEach((listener) => {
          this.addEventListener(type, listener);
        });
      });
    }
  }

  addEventListener(event, listener) {
    const fn = listener.bind(this);
    if (!this.events.has(event)) {
      this.events.add(event);
      document.addEventListener(event, this, { capture: true });
    }
    this.listeners.set(event, (this.listeners.get(event) || []).concat(fn));
    if (event === "DOMContentLoaded" && this.initialized) {
      fn(new Event(event));
    }
  }

  handleEvent(event) {
    if (this.initialized) {
      this.listeners.get(event?.type || event)?.forEach((fn) => fn(event));
    }
  }

  init() {
    this.initialized = true;
    if (document.readyState === "complete") {
      this.handleEvent(new Event("DOMContentLoaded"));
    }
    return this;
  }

  removeEventListener(event, fn) {
    if (this.listners.has(event)) {
      const listeners = this.listeners
        .get(event)
        ?.filter((listener) => listener !== fn);
      this.listeners.set(event, listeners);
    }
  }
}
