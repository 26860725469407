import { storage } from "../utils";

export function handleInvalid(event) {
  const elements = event.target?.elements || event.target?.form?.elements || [];
  Array.from(elements).forEach((el) => {
    const isValid = !el.validity || el.validity.valid;
    if (!isValid) {
      setValidityState(el);
      el.addEventListener("change", handleInvalidElementChange);
    }
  });
}

export async function handleSubmit(event) {
  event.preventDefault();
  const form = event.target;
  if (form instanceof HTMLFormElement && form.action && form.method === "post")
    try {
      const formData = new FormData(form);
      const response = await fetch(form.action, {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams(formData).toString(),
      });
      const html = await response.text();
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, "text/html");
      const confirmation = doc.getElementById("form-confirmation");
      if (confirmation) {
        form.scrollIntoView(true);
        form.innerHTML = confirmation.innerHTML;
      }
    } catch (error) {
      //
    }
}

export function persistFormData() {
  Array.from(document.forms).forEach((form) => {
    if (form.hasAttribute("name")) {
      const name = `form_${form.getAttribute("name")}`;
      const data = new FormData(form);
      storage.session.set(name, Array.from(data.entries()));
    }
  });
}

export function restoreFormData() {
  Array.from(document.forms).forEach((form) => {
    if (form.hasAttribute("name")) {
      const name = `form_${form.getAttribute("name")}`;
      const data = storage.session.get(name);
      if (data) {
        data.forEach(([el, value]) => {
          if (form[el]) {
            // eslint-disable-next-line no-param-reassign
            form[el].value = value;
          }
        });
      }
    }
  });
}

function handleInvalidElementChange(event) {
  if (
    event.target instanceof HTMLElement &&
    event.target.matches("input, select, textarea")
  ) {
    event.target.checkValidity();
    event.target.removeEventListener("change", handleInvalidElementChange);
    setValidityState(event.target);
  }
}

function setValidityState(el) {
  if (el instanceof HTMLElement && el.matches("input, select, textarea")) {
    const isValid = !el.validity || el.validity.valid;
    el.setAttribute("aria-invalid", (!isValid).toString());
    const component = el.closest("[data-component='form-control']");
    if (component && isValid) {
      delete component.dataset.invalid;
    } else if (component && !isValid) {
      component.dataset.invalid = true;
    }
  }
}
