/**
 * Naive in-memory caching
 *
 * A wrapper around the native Map that adds an entry limit to prevent memory
 * leaks (32 by default). Use { max: Infinity } for unlimited cache size.
 */
export default class Cache {
  constructor(options = {}) {
    const defaults = { max: 32 };
    this.options = { ...defaults, ...options };
    this.map = new Map();
  }

  get size() {
    return this.map.size;
  }

  get entries() {
    return Array.from(this.map.entries());
  }

  clear() {
    return this.map.clear();
  }

  delete(key) {
    return this.map.delete(key);
  }

  get(key) {
    return this.map.get(key);
  }

  has(key) {
    return this.map.has(key);
  }

  set(key, item) {
    if (this.map.size >= this.options.max) {
      this.map.delete(this.map.keys()[0]);
    }
    return this.map.set(key, item);
  }
}
