export function isDataRestricted() {
  return (
    "connection" in navigator &&
    (navigator.connection.saveData === true ||
      navigator.connection.effectiveType === "slow-2g" ||
      navigator.connection.effectiveType === "2g")
  );
}

export function unslash(str) {
  return str.replace(/^\/+|\/+$/g, "");
}
