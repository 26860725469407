import { unslash } from "../utils/helpers";
import Cache from "../utils/Cache";

const cache = new Cache();

function prefetchLinks(event) {
  const a = event.target.closest("a");

  if (a && !cache.has(a.href)) {
    if (
      a.host === window.location.host &&
      unslash(a.pathname) !== unslash(window.location.pathname)
    ) {
      let link = document.head.querySelector(
        `link[rel="prefetch"][href="${a.href}"]`,
      );
      if (!link) {
        link = document.createElement("link");
        Object.assign(link, {
          as: "fetch",
          crossOrigin: "anonymous",
          href: a.href,
          rel: "prefetch",
        });
        document.head.appendChild(link);
      }
      cache.set(a.href, link);
    } else {
      cache.set(a.href, false);
    }
  }
}

export function addNavigationHandlers() {
  const options = { capture: true, passive: true };
  this.addEventListener("touchstart", prefetchLinks, options);
  this.addEventListener("mouseover", prefetchLinks, options);
}
